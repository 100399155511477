import { useEffect } from "react";

const InstructionPopup = () => {
  useEffect(() => {
    sessionStorage.setItem("showPopup", true);
  }, []);

  return (
    <div className="p-3 bg-[#4f6461] rounded-lg">
      <div className="border-2 border-[#b68d40] p-2 mb-2 rounded-md">
        <h3 className="text-[#b68d40] font-bold">Width</h3>
        <p className="text-[#b68d40] font-semibold">
          Apply only the width of the frame you want (we will increase the
          required length)
        </p>
        <p className="text-[#b68d40]">
          ඔබට අවශ්‍ය රමුවේ පළල ප්‍රමාණයක් යොදන්න (අවශ්‍ය ප්‍රමාණය අප විසින් වැඩි
          කරනු ලැබේ)
        </p>
      </div>

      <div className="border-2 border-[#b68d40] p-2 mb-2 rounded-md">
        <h3 className="text-[#b68d40] font-bold">Height</h3>
        <p className="text-[#b68d40] font-semibold">
          Height from 48 inches to 102 inches there is no change in price so use
          maximum height available.
        </p>
        <p className="text-[#b68d40]">
          උස අඟල් 48 සිට අඟල් 102 දක්වා මිලේසි වෙනස් කිරීමක් සිදු නොවන නිසා
          ගැළපෙන උපරිම උස භාවිතා කරන්න.
        </p>
      </div>

      <div className="border-2 border-[#b68d40] p-2 rounded-md">
        <h3 className="text-[#b68d40] font-bold">Pleats</h3>
        <p className="text-[#b68d40] font-semibold">
          Increase or decrease this as per your ability
        </p>
        <p className="text-[#b68d40]">ඔබගේ හැකියාවට පරිදි මෙය අඩු වැඩි කරන්න</p>
      </div>
    </div>
  );
};

export default InstructionPopup;
