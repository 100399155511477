export const meetUS = {

  "Meet Us":{ 
    en: "Meet Us", 
    si: "අපව හමුවීමට" ,
  },

  "Hotline":{ 
    en: "Hotline", 
    si: "ක්ෂණික ඇමතුම්", 
  },

  "Get Directions":{ 
    en: "Get Directions", 
    si: "සිතියම වෙත පිවිසෙන්න", 
  },

  "With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are.":{
    en: "With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are.",
    si: "ඔබේ සිතැගි පරිදි, ඔබට ගැලපෙන තිර රෙදි මෝස්තර තොරගන්න, ඔබට ලගම අපගේ ප්‍රදර්ශනාගාරය වෙත පිවිසෙන්න.",
  },

  "Ragama":{ 
    en: "Ragama", 
    si: "රාගම", 
  },

  "Walpola":{ 
    en: "Walpola", 
    si: "වල්පොල", 
  },

  "Welimada":{ 
    en: "Welimada", 
    si: "වැලිමඩ", 
  },

  "Kurunegala":{ 
    en: "Kurunegala", 
    si: "කුරුණෑගල", 
  },

  "No 72, Thewatha Road, Ragama":{ 
    en: "No 72, Thewatha Road, Ragama", 
    si: "අංක 72, තේවත්ත පාර, රාගම.",
  },

  "No 16, Walpola Ragama":{ 
    en: "No 16, Walpola Ragama", 
    si: "අංක 16, වල්පොල, රාගම." 
  },
    
  "9th Post Nawela, Mirahawaththa, Welimada":{ 
    en: "9th Post Nawela, Mirahawaththa, Welimada", 
    si: "9 වැනි තැපෑල නාවෙල, මිරහවත්ත, වැලිමඩ",
  },

  "Danbokka Junction, Colombo Rd, Kurunegala":{ 
    en: "Danbokka Junction, Colombo Rd, Kurunegala", 
    si: "දන්බොක්ක හන්දිය, කොළඹ පාර, කුරුණෑගල",
  },
  
};