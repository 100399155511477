export const item = {

    "Selection added to the cart successfully. Do you want to continue": {
        en:"Selection added to the cart successfully. Do you want to continue",
        si:"තේරීම සාර්ථකව ලැයිස්තුවට එක් කරන ලදී. ඔබට දිගටම කරගෙන යාමට අවශ්‍යද?"
    },
    "shopping or go to the cart?": {
        en:"shopping or go to the cart?",
        si:"තවත් තිර රෙදි ‌තේරීමට හෝ ලැයිස්තුවට යන්නද?"
    },
    "Continue Shopping": {
        en:"Continue Shopping",
        si:"තවත් තිර රෙදි ‌තේරීමට"
    },
    "Go to Cart": {
        en:"Go to Cart",
        si:"ලැයිස්තුවට යන්න"
    },
    "Customize Selected Design": {
        en:"Customize Selected Design",
        si:"දොර/ජනෙල්වල ප්‍රමාණ ඇතුලත් කරන්න"
    },
    "Type": {
        en:"Type",
        si:"වර්ගය"
    },
    "Frame Width": {
        en:"Frame Width",
        si:"රාමු පළල"
    },
    "Inches": {
        en:"Inches",
        si:"අඟල්"
    },
    "Height": {
        en:"Height",
        si:"උස"
    },
    "Number of Pleats": {
        en:"Number of Pleats",
        si:"රැලි ගණන"
    },
    "Quantity": {
        en:"Quantity",
        si:"ප්‍රමාණය"
    },
    "Price per unit": {
        en:"Price per unit",
        si:"එක් තිර රෙදි රඳවනයක මිල"
    },
    "Total": {
        en:"Total",
        si:"මුළු වටිනාකම"
    },
    "User Guide": {
        en:"User Guide",
        si:"වැඩිදුර විස්තර සඳහා කියවන්න"
    },
    "How to measure?": {
        en:"How to measure?",
        si:"මිනුම් ලබාගන්නා ආකාරය"
    },
    "All measurements should be in inches": {
        en:"All measurements should be in inches",
        si:"සියලුම මිනුම් අඟල් වලින් විය යුතුය"
    },
    "Width of the Window/Door outer frame": {
        en:"Width of the Window/Door outer frame",
        si:"ජනේලයේ/දොර පිටත රාමුවේ පළල"
    },
    "Height of the Window/Door outer frame": {
        en:"Height of the Window/Door outer frame",
        si:"ජනේලයේ/දොර පිටත රාමුවේ උස"
    },
    "Measurement from the floor to the bottom of the window": {
        en:"Measurement from the floor to the bottom of the window",
        si:"පොළවේ සිට ජනේලයේ පහළ කොටස දක්වා උස"
    },
    "frame": {
        en:"frame",
        si:" බිත්තියේ උස"
    },
    "Measurement from the ceiling/ roof/ slab to the top of": {
        en:"Measurement from the ceiling/ roof/ slab to the top of",
        si:"සිවිලිම හෝ  වහලයේ සිට ජනේලයේ ඉහළ කොටස දක්වා"
    },
    "the window frame": {
        en:"the window frame",
        si:" බිත්තියේ උස"
    },
    "Full height from the floor to the ceiling/ slab/ roof": {
        en:"Full height from the floor to the ceiling/ slab/ roof",
        si:"පොළවේ සිට සිවිලිම හෝ  වහලය දක්වා සම්පූර්ණ උස"
    },
    "(Not essential)": {
        en:"(Not essential)",
        si:" (අත්‍යවශ්‍ය නොවේ)"
    },
    "Recommended": {
        en:"Recommended",
        si:"නිර්දේශ කර ඇත"
    },
    "Type & Width": {
        en:"Type & Width",
        si:"දොර/ජනෙල් වර්ගය සහ ඒවායේ පළල"
    },
    "The width of door & windows are predicted as below": {
        en:"The width of door & windows are predicted as below",
        si:"දොර/ජනෙල් වර්ගය වල පළල පහත පරිදි මිනුම් කර ඇත"
    },
    "Depending on the size of the door and window frame, add or": {
        en:"Depending on the size of the door and window frame, add or",
        si:"ඔබගේ දොර/ජනෙල් රාමුවේ පළල පහත සඳහන් කොටුවේ ඇති පළලට වඩා වැඩි නම්, (+) එකතු කිරීමේ ලකුණ මගින් පළල වැඩි කරගත හැක. "
    },
    "subtract the value in the Width box.": {
        en:"subtract the value in the Width box.",
        si:"පහත සඳහන් කොටුවේ ඇති පළලට වඩා අඩු නමි නම්, (-) අඩු කිරීමේ ලකුණ මගින් පළල අඩු කරගත හැක. "
    },
    "Simply specify the inside width of your window frame and we will": {
        en:"Simply specify the inside width of your window frame and we will",
        si:"ඔබගේ දොර/ජනෙල් රාමුවේ ඇතුළත පළල පමණක් සඳහන් කරන්න."
    },
    "increase it by 6 inches on each side.": {
        en:"increase it by 6 inches on each side.",
        si:" ඔබ ඇතුලත් කරන පළලේ ප්‍රමාණයට අප විසින් දෙපසින්ම අගල් 6 බැගින් එකතු කරනු ලැබේ."
    },
    "It is adequate to mention the necessary height in inches and the": {
        en:"It is adequate to mention the necessary height in inches and the",
        si:"අවශ්‍ය උස අඟල් වලින් සඳහන් කරන්න "
    },
    "cost differs according to three sizes of height.": {
        en:"cost differs according to three sizes of height.",
        si:"උස ප්‍රමාණ තුනක් අනුව පිරිවැය වෙනස් වේ."
    },
    "1. Less than 47 inches (this reduce a half of the cost of": {
        en:"1. Less than 47 inches (this reduce a half of the cost of",
        si:"1. අඟල් 47 ට අඩු (ඔබේ තිර රෙදි නිර්මාණය සඳහා අමුද්‍රව්‍ය අඩක් පමණක් යොදා ගන්නා නිසා  "
    },
    "material in your design) Conditions Apply": {
        en:"material in your design) Conditions Apply",
        si:"වියදමින් අඩක් පමණ අඩු වේ.) කොන්දේසි අදාළ වේ"
    },
    "2. From 48 inches up to 102 inches": {
        en:"2. From 48 inches up to 102 inches",
        si:"2. අඟල් 48 සිට අඟල් 102 දක්වා"
    },
    "3. From 103 inches to above": {
        en:"3. From 103 inches to above",
        si:"3. අඟල් 103 සිට ඉහළට"
    },
    "If 100 inches height is maintained a fantastic finish is": {
        en:"If 100 inches height is maintained a fantastic finish is",
        si:"අඟල් 100 ක උසකින් යොදා ගන්නේ  නම් විශිෂ්ට නිමාවක් "
    },
    "obtainable for your design": {
        en:"obtainable for your design",
        si:"ඔබගේ තිර රෙදි සඳහා ලබා ගත හැක"
    },
    "Pleats": {
        en:"Pleats",
        si:"තිර රෙදි සඳහා රැලි යොදා ගැනීම"
    },
    "Usual practice is inserting 3 pleats per foot (The International": {
        en:"Usual practice is inserting 3 pleats per foot (The International",
        si:"සාමාන්‍ය ක්‍රමය වන්නේ අඩියකට රැලි 3 ක් ඇතුල් කිරීමයි (ජාත්‍යන්තර"
    },
    "Standards is 14 inch gap between two pleats). Accordingly, a": {
        en:"Standards is 14 inch gap between two pleats). Accordingly, a",
        si:"සම්මතයට අනුව රැලි දෙකක් අතර අඟල් 14 ක පරතරයක් තිබිය යුතුයි). ඒ අනුව,"
    },
    "quantity of material is been used,": {
        en:"quantity of material is been used,",
        si:"දොර/ජනෙල්  සදහා භාවිතා කරන ලද රැලි  ප්‍රමාණ"
    },
    "The number of pleats can be decreased or increased according to": {
        en:"The number of pleats can be decreased or increased according to",
        si:"ඔබගේ කැමැත්ත පරිදි රැලි ප්‍රමාණය අඩු හෝ වැඩි කරගත හැක."
    },
    "your probability and requirement. The price depends with number": {
        en:"your probability and requirement. The price depends with number",
        si:" රැලි ප්‍රමාණය අඩු කිරීමට (-) අඩු කිරීමේ ලකුණ සහ වැඩි කිරීමට (+) එකතු කිරීමේ ලකුණ භාවිතා කරන්න"
    },
    "of pleats on your decision.": {
        en:"of pleats on your decision.",
        si:" ඔබ විසින් යොදාගන්නා රැලි ප්‍රමාණය අනුව තිර රෙදි නිර්මාණයට වැය වන මුදල වෙනස් වේ."
    },
    "Notice": {
        en:"Notice",
        si:"දැනුම්දීම"
    },
    "If transparent curtains (sheer) are used for your window frames it": {
        en:"If transparent curtains (sheer) are used for your window frames it",
        si:"ඔබේ ජනෙල් සඳහා විනිවිද පෙනෙන තිර (පැහැදිලි) භාවිතා කරන්නේ නම්"
    },
    "is appropriate to increase a few number of pleats": {
        en:"is appropriate to increase a few number of pleats",
        si:" රැලි කිහිපයක් වැඩි කිරීමට සුදුසු වේ"
    },
    "Poles": {
        en:"Poles",
        si:"තිර රෙදි රඳවන "
    },
    "For the purpose of curtain brackets of or own designs have been": {
        en:"For the purpose of curtain brackets of or own designs have been",
        si:"තිර රෙදි රඳවන සඳහා අප මේ දක්වා භාවිත කර ඇත්තේ අප විසින්ම නිර්මාණය කරන "
    },
    "utilized to date and it is made of standard galvanized tubes and": {
        en:"utilized to date and it is made of standard galvanized tubes and",
        si:" රඳවන පමණක් වන අතර එය නිර්මාණය කරනුයේ ප්‍රමිතියකින් යුතු ඝන ගැල්වනයිස්"
    },
    "quality wood. A long time warranty is granted and the benefits": {
        en:"quality wood. A long time warranty is granted and the benefits",
        si:" බටයකින් හා හොඳ තත්වයේ දැව වර්ගයකිනි.මේ සඳහා දිගු කාලීන වගකීමක් "
    },
    "you enjoy are,": {
        en:"you enjoy are,",
        si:" අප ලබා දෙන අතර එයින් ඔබට ලැබෙන වාසි නම්,"
    },
    "As we charge only the production cost the prices prevail at a low": {
        en:"As we charge only the production cost the prices prevail at a low",
        si:"මෙහි නිෂ්පාදන පිරිවැය පමණක් අප විසින් අය කරනු ලබන නිසා"
    },
    "rate.": {
        en:"rate.",
        si:" අපගේ නිර්මාණ වල මිල ගණන් පහළ මට්ටමක පැවතීම."
    },
    "As the bracket is provided with screws for installing facility and": {
        en:"As the bracket is provided with screws for installing facility and",
        si:"සවි කිරීම පහසු වීම සඳහා අල්ලුවට ඇණ සවි කර ඇති නිසා"
    },
    "It is convenient to fix on the wall.": {
        en:"It is convenient to fix on the wall.",
        si:" එය බිත්තියට පහසුවෙන් සවිකල හැකි වීම."
    },
    "Depending on the location of walls and windows our brackets": {
        en:"Depending on the location of walls and windows our brackets",
        si:"ඔබගේ බිත්ති හා ජනෙල් වල පිහිටීම අනුව විවිධ හැඩතල සහිත"
    },
    "consists of various shaped accessories.": {
        en:"consists of various shaped accessories.",
        si:"උපාංග අප රඳවන සතුව පැවතිම."
    },
    "The fastener is furnished with a good quality wood (with no": {
        en:"The fastener is furnished with a good quality wood (with no",
        si:"අල්ලුව සඳහා හොද තත්වයේ තනි දැවයකින් (ඇලවීමක් සිදුකර නැත)"
    },
    "adhesive) the security is high.": {
        en:"adhesive) the security is high.",
        si:"  නිම කර ඇති නිසා ආරක්ෂාව අතින් ඉහළ මට්ටමක පැවතීම."
    },
    "It is long lasting": {
        en:"It is long lasting",
        si:"ඉතා දිගු කාලීන පවැත්මක් පැවතීම."
    },
    "Attractive and available in many colours": {
        en:"Attractive and available in many colours",
        si:"මනා පෙනුමක් සහිතව වර්ණ රැසකින් ලබා ගත හැකි වීම."
    },
    "Being able to contribute to saving the environment as less wood is": {
        en:"Being able to contribute to saving the environment as less wood is",
        si:"දැව අඩුවෙන් භාවිතා වන නිසා"
    },
    "used.": {
        en:"used.",
        si:" පරිසරය සුරැකීමට දායක වීමට හැකි වීම."
    },
    "As this is our own design this is not available in the open": {
        en:"As this is our own design this is not available in the open",
        si:"මෙය අපගේ නිර්මාණයක් වන බැවින් "
    },    
    "market & accessories such as poles are not issued on your": {
        en:"market & accessories such as poles are not issued on your",
        si:"වෙළදපොලෙන් ලබා ගැනීම හෝ  වෙන් වශයෙන් මිලදි ගත "
    },
    "request.": {
        en:"request.",
        si:" නොහැක."
    },
    "You can select from here whether you need or not a pole": {
        en:"You can select from here whether you need or not a pole",
        si:"ඔබට තිර රෙදි රඳවන අවශ්‍යද නැද්ද යන්න ඔබට මෙතැනින් තෝරා ගත හැක"
    },
    "Door": {
        en:"Door",
        si:"දොර"
    },
    "Single Window": {
        en:"Single Window",
        si:"තනි පියන ජනේලය"
    },
    "Double Window": {
        en:"Double Window",
        si:"පියන් 2 ජනේලය"
    },
    "Triple Window": {
        en:"Triple Window",
        si:"පියන් 3 ජනේලය"
    },
    "Quadruple Window": {
        en:"Quadruple Window",
        si:"පියන් 5 ජනේලය"
    },
    "Other": {
        en:"Other",
        si:"වෙනත්"
    },
    "View Description": {
        en:"View Description",
        si:"විස්තරය බලන්න"
    },
    "Without Pole": {
        en:"Without Pole",
        si:"රඳවන නොමැතිව"
    },
    "With Pole": {
        en:"With Pole",
        si:"රඳවන සමඟ"
    },
    "Add to Cart": {
        en:"Add to Cart",
        si:"ලැයිස්තුවට එක් කරන්න"
    }  
};