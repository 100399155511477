export const cart = {

  Islandwide: {
    en: "Islandwide",
    si: "දිවයින පුරා",
  },

  Service: {
    en: "Service",
    si: "සේවාව",
  },

  "My Cart": {
    en: "My Cart",
    si: "මගේ ලැයිස්තුව",
  },

  "Additional Services": {
    en: "Additional Services",
    si: "අමතර සේවාවන්",
  },

  "Order Summary": {
    en: "Order Summary",
    si: "ඇණවුම් සාරාංශය",
  },

  Step: {
    en: "Step",
    si: "පියවර",
  },

  "Fixing Required": {
    en: "Fixing Required",
    si: "සවි කිරීමට අවශ්‍යද?",
  },

  "Transport Required": {
    en: "Transport Required",
    si: "ප්‍රවාහන අවශ්‍යද?",
  },

  "Total Quantities": {
    en: "Total Quantities",
    si: "සම්පුර්ණ කට්ටල ප්‍රමාණය",
  },

  "Pole Width": {
    en: "Pole Width",
    si: "රදවන වල සම්පුර්න දිග",
  },

  "Total Pleats": {
    en: "Total Pleats",
    si: "සම්පුර්ණ රැලි ගණන",
  },

  "Fixing Cost": {
    en: "Fixing Cost",
    si: "සවි කිරීමේ ගාස්තු",
  },

  "Transportation Cost": {
    en: "Transportation Cost",
    si: "ප්‍රවාහන ගාස්තු",
  },

  Total: {
    en: "Total",
    si: "මුළු එකතුව",
  },

  "Please fill your details to get your quotation.": {
    en: "Please fill your details to get your quotation.",
    si: "ඔබේ මිලගණන් ලබා ගැනීමට තොරතුරු සම්පූර්ණ කරන්න.",
  },

  "You will receive the quote via an Email": {
    en: "You will receive the quote via an Email",
    si: "ඔබට මිලගණන් ඊ-මේල් එකක් මගින් ලැබේවි",
  },

  "Get a quotation for the items in the cart": {
    en: "Get a quotation for the items in the cart",
    si: "ලැයිස්තුව අයිතම සඳහා මිල ගණන් ලබා ගන්න",
  },

  "Clear Cart": {
    en: "Clear Cart",
    si: "හිස් කරන්න",
  },

  "Please wait ...": {
    en: "Please wait ...",
    si: "කරුණාකර රැදී සිටින්න...",
  },

  "Item Code": {
    en: "Item Code",
    si: "අයිතම කේතය",
  },

  "Unit Price": {
    en: "Unit Price",
    si: "ඒකක මිල",
  },

  Quantity: {
    en: "Quantity",
    si: "ප්‍රමාණය",
  },

  Type: {
    en: "Type",
    si: "වර්ගය",
  },
  
  Width: {
    en: "Width",
    si: "පළල",
  },

  Height: {
    en: "Height",
    si: "උස",
  },

  Pleats: {
    en: "Pleats",
    si: "රැලි",
  },

  Pole: {
    en: "Pole",
    si: "තිර රෙදි රදවන",
  },

  Yes: {
    en: "Yes",
    si: "ඔව්",
  },

  No: {
    en: "No",
    si: "නැත",
  },

  Title: {
    en: "Title",
    si: "ශීර්ෂය",
  },

  "First Name": {
    en: "First Name",
    si: "මුල් නම",
  },

  "Last Name": {
    en: "Last Name",
    si: "අවසන් නම",
  },

  "Telephone / Whatsapp Number": {
    en: "Telephone / Whatsapp Number",
    si: "දුරකථන / වට්ස්ඇප් අංකය",
  },

  Email: {
    en: "Email",
    si: "ඊ-මේල් ලිපිනය",
  },

  "Address Line 1": {
    en: "Address Line 1",
    si: "ලිපිනය",
  },
  
  "Address Line 2": {
    en: "Address Line 2",
    si: "නගරය",
  },

  "Get a Quotation": {
    en: "Get a Quotation",
    si: "මිල කැදයුමක් ලබා ගන්න",
  },
  
  Distance: {
    en: "Distance",
    si: "දුර",
  },
  
};