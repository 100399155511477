export const contactUs = {

  "Get in": {
    en: "Get in",
    si: "අප හා",
  },

  "Touch": {
    en: "Touch",
    si: "සම්බන්ධ",
  },

  "Today": {
    en: "Today",
    si: "වන්න",
  },

  "Hotline": {
    en: "Hotline",
    si: "ක්ෂණික ඇමතුම්", 
  },

  "Email": {
    en: "Email",
    si: "ඊ-මෙල් ",
  },

  "Facebook": {
    en: "Facebook",
    si: "ෆේස්බුක්",
  },

  "YouTube": {
    en: "YouTube",
    si: "යූටියුබ්",
  },
  
  "Meet Us": {
    en: "Meet Us",
    si: "අපව හමුවීමට",
  }
  
};